.workspace-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navbar {
    height: 50px;
    background-color: #f5f5f5;
    padding: 15px;
}

.workspace-content {
    display: flex;
    flex-grow: 1;
    padding: 20px;
}

.sidebar,
.right-sidebar {
    width: 20%;
    max-height: 100vh; /* you can adjust this value */
    overflow-y: auto; /* enable vertical scroll when needed */
    /*background-color: #e5e5e5;*/
    padding: 10px;
}

.main-content {
    width: 70%;
    margin: 0 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-width: 1px;
    border-radius: 20px;
    border-color: #464DF2;
    border-style: solid;
}

.row-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

hr {
    border: 1px solid lightgrey;
    margin: 20px 0;
}

.vertical-divider {
    width: 1px;
    height: 100%;
    background-color: lightgrey;
    margin: 0 10px;
}

.rounded-components .component {
    border-radius: 10px;
    border: 1px solid lightgrey;
    padding: 10px;
}

.rounded-components .title {
    background-color: #3498db;
    color: white;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    margin: -10px -10px 10px -10px;
}

/* Additional CSS to style other elements, like the list of information under each title, can be added below. */
