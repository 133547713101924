.card-component {
    border-radius: 20px;
    border: 1px solid lightgrey;
    padding: 10px;
    flex-grow: 1;
    margin: 0 10px;
}

.card-title {
    background-color: #3498db;
    color: #2F4A5B;
    font-weight: 500;
    padding: 5px;
    border-radius: 20px 20px 0 0;
    margin: -10px -10px 10px -10px;
}

.card-info .info-key {
    color: lightgrey;
    display: block;
}

.card-info .info-value {
    color: black;
    display: block;
    margin-bottom: 5px;
}
