.card {
    width: 83%;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #464DF2;
    box-shadow: 0px 0px 32px rgba(70, 77, 242, 0.24);
    border-radius: 20px;
    position: relative;
    padding: 16px;
    margin-bottom: 8px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status {
    background: rgba(70, 77, 242, 0.04);
    border-radius: 16px;
    font-family: 'SF Pro Text';
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    color: #464DF2;
    padding: 2px 8px;
}

.notif-icon {
    width: 24px;
    height: 24px;
    background: #2F4A5B;
    /*position: absolute;*/
    top: 16px;
    right: 16px;
}

.card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.availability, .processability {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #24DE6C;
}

.card-footer {
    font-family: 'Roboto';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dataset-id {
    font-weight: 500;
    font-size: 10px;
    color: rgba(47, 74, 91, 0.56);
}

.gse {
    font-weight: 700;
    font-size: 16px;
    color: #2F4A5B;
}
